import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './About.scss';

const Home = () => {

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setIsVisible(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setIsVisible(false);
      });
    }
  };

  return (
    <div className="home">
        <Header />
          <div className='container'>
            <div className='row'>
              <div className='col'><img className="aboutImage" src="/cinemaCredits.png"></img></div>
              <div className='col'><p>PostCredit.app helps you find and explore movies with post-credit scenes by scraping data from <a rel="noopener" target="_blank" className='link' href="https://en.wikipedia.org/wiki/List_of_films_with_post-credits_scenes">Wikipedia</a>. Easily search for movies, view detailed information about post credit scenes, and stay updated with the latest additions. Perfect for movie enthusiasts who don't want to miss any hidden gems after the credits roll!</p></div>
            </div>
          {isVisible && (
            <div>
              <p>PostCredit.app is also available as a Progressive Web App, you can open the menu and click "Add to Home Screen" or similar option or use the button below to have the app handy.</p>
              <button className="install" onClick={handleInstallClick}>
                Install PWA App
              </button>
            </div>
            
          )}
          </div>
          
        <Footer />
    </div>
  );
};

export default Home;


        
    