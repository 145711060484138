import React from 'react';
import './Header.scss';

const Header = () => {
  return (
    <header className="App-header">
            <a href="/"><img width="32" heigh="32" src="/movie.svg" alt="PostCredit.app logo"></img><h1>PostCredit.app</h1></a>
    </header>
  );
};

export default Header;


        
    