import React from 'react';
import './Footer.scss'

const Footer = () => {
  return (
    <footer className="App-footer">
            <a target='_blank' rel="noopener" href="https://buymeacoffee.com/postcreditapp">Buy me a Coffee ☕</a>
            <a href="/about" className='aboutLink'>About</a>
    </footer>
  );
};

export default Footer;