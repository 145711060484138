import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './MovieDetails.scss';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const MovieDetails = () => {
  const { id } = useParams();
  const [movie, setMovie] = useState(null);
  const [blurred, setBlurred] = useState(true);

  const removeBlur = () => {
    setBlurred(false);
  };

  useEffect(() => {
    const fetchMovieDetails = async () => {
      try {
        console.log(`Fetching details for movie with ID: ${id}`);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/movies/${id}`);
        
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          const data = await response.json();
          console.log('Fetched movie details:', data);
          if (data) {
            setMovie(data);
          } else {
            console.error('No movie found with the given ID');
          }
        } else {
          console.error('Error: Expected JSON response');
        }
      } catch (error) {
        console.error('Error fetching movie details:', error);
      }
    };

    fetchMovieDetails();
  }, [id]);

  if (!movie) {
    return <div className='loading'><div className="spinner"></div></div>;
  }

  return (
    <div>
      <Header />
      <div className='movie-info'>
        <img src={`https://image.tmdb.org/t/p/w500${movie.poster}`} alt={`${movie.title} poster`} width="200" />
        <div className='movie-copy'>
          <h2>{movie.title}</h2>
          <span><strong>Release date:</strong> {movie.year}</span>
          <p>
            <strong>Description: </strong>
            <span className={`spoiler ${blurred ? '' : 'unblurred'}`} onClick={removeBlur}>{movie.description}</span>
          </p>
        </div>
      </div>
      <Footer />
      <a className='start-over' href="/">Start Over</a>
    </div>
  );
};

export default MovieDetails;
