import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-W9DVLCWZPF');
// Create a root and render the App component into it

if('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js');
  });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
