import React from 'react';
import SearchMovies from '../SearchMovies/SearchMovies';
import LatestMovies from '../LatestMovies/LatestMovies';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const Home = () => {
  return (
    <div className="home">
      <div className="App">
      <Header />
      </div>
        <SearchMovies />
        <LatestMovies />
        <Footer />
    </div>
  );
};

export default Home;


        
    