import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import './SearchMovies.scss';

const SearchMovies = () => {
  const [query, setQuery] = useState('');
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMovies = async (searchQuery) => {
      if (searchQuery) {
        setLoading(true);
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/movies/search?query=${searchQuery}`);
          
          const contentType = response.headers.get('content-type');
          if (contentType && contentType.includes('application/json')) {
            const data = await response.json();
            console.log('Fetched movies:', data);
            setMovies(data);
            setNotFound(data.length === 0);
          } else {
            console.error('Error: Expected JSON response');
            setNotFound(true);
          }
        } catch (error) {
          console.error('Error fetching movies:', error);
          setNotFound(true);
        } finally {
          setLoading(false);
        }
      } else {
        setMovies([]);
        setNotFound(false);
        setLoading(false);
      }
    };

    const debouncedFetchMovies = debounce(fetchMovies, 300);
    debouncedFetchMovies(query);

    return () => {
      debouncedFetchMovies.cancel();
    };
  }, [query]);

  const handlePosterClick = (id) => {
    navigate(`/movie/${id}`);
  };

  return (
    <div>
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Start typing the movie name"
        className='search-bar'
      />
      {loading ? (
        <div className="spinner"></div>
      ) : notFound ? (
        <p className='not-found'>Movie not found in the database</p>
      ) : (
        <ul className='movies'>
          {movies.map((movie) => (
            <li key={movie.id} className='single-movie'>
              {movie.poster && (
                <div className='movie-card' onClick={() => handlePosterClick(movie.tmdb_id)}>
                  <div className='img-container'>
                    <img src={`https://image.tmdb.org/t/p/w300${movie.poster}`} alt={`${movie.title} poster`} width="300" style={{ cursor: 'pointer' }} />
                  </div>
                  <h3>{movie.title}</h3>
                  <span>({movie.year})</span>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchMovies;
