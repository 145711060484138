import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LatestMovies.scss';

const LatestMovies = () => {
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLatestMovies = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/movies/latest`);
        
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          const data = await response.json();
          setMovies(data);
        } else {
          throw new Error('Expected JSON response');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLatestMovies();
  }, []);

  if (loading) {
    return <div><div className="spinner"></div></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handlePosterClick = (id) => {
    navigate(`/movie/${id}`);
  };

  return (
    <div className="latest-movies">
      <h2>Latest Post Credit Scenes</h2>
      <ul className="movies">
        {movies.map((movie) => (
          <li key={movie.id} className="single-movie">
            {movie.poster && (
              <div className="movie-card" onClick={() => handlePosterClick(movie.tmdb_id)}>
                <div className="img-container">
                  <img src={`https://image.tmdb.org/t/p/w300${movie.poster}`} alt={`${movie.title} poster`} width="300" />
                </div>
                <h3>{movie.title}</h3>
                <span>({movie.year})</span>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LatestMovies;
